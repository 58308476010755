.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rs-table {
  border-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rs-table-cell-content {
  padding: 0 !important;
}

.adm-rs-table-cell-header-summary-wrapper {
  display: inline-block;
}
.adm-rs-table-cell-header-summary-content {
  font-size: 14px;
  color: #2eabdf;
  position: relative;
  /*right: -16px;*/
}

.rs-table-row-header {
  color: #636367;
  font-weight: bold;
}

.adm-rs-table-cell-header-summary-wrapper + .rs-table-cell-header-sort-wrapper,
.rs-table-column-group-cell-content + .rs-table-cell-header-sort-wrapper {
  position: relative;
  top: -25px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  border-color: red !important;
}